/* ==========================================================================
   Custom theme skin
   ========================================================================== */

/* Colors */
$base-color: #16001E !default;
$text-color: #eaeaea !default;
$accent-color: #DE639A !default;
$background-color: #16001E !default;
$code-background-color: #16001E !default;
$border-color: rgba($text-color, 0.5) !default;
$overlay-color: #16001E !default;
